* { padding: 0; margin: 0;}

html {
    scroll-behavior: smooth;
}

body {
    width: 100vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    top: 0;
    left: 0;
    color: rgb(240, 240, 240);
    background-color: rgb(30, 30, 30);
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}

h1 {
  text-align: center;
  font-size: 4em;
  transition: 0.5s;
}

h1:hover {
  font-size: 4.5em;
}

h2 {
  text-align: center;
  font-size: 2em;
  font-weight: normal;
  transition: 0.5s;
}

h2:hover {
  font-size: 2.5em;
}

.bg {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url("https://source.unsplash.com/1600x900/?nature");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: blur(10px);
  opacity: 0.7;
}

.center {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}

.grid-container {
  width: 50vw;
  min-width: 100%;
  display: inline-grid;
  grid-template-columns: auto auto auto;
  padding: auto;
}

.item {
  width: 10vw;
  height: 10vw;
  margin: auto;
  margin-top: 5vh;
  background-color: #fff12f;
  border-radius: 15vw;
}

.image {
  width: 10vw;
  height: 10vw;
  min-width: 100px;
  min-height: 100px;
  margin: auto;
  margin-top: 5vh;
  object-fit: cover;
  border-radius: 100%;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.8);
  transition: 0.5s;
}

.image:hover {
  width: 15vw;
  height: 15vw;
}